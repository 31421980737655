@layer base {
    @font-face {
        font-family: "Boing";
        font-weight: 300;
        src: local("Boing"), url("../fonts/Boing/Boing-Light.ttf") format("truetype");
    }

    @font-face {
        font-family: "Boing";
        font-weight: 400;
        src: local("Boing"), url("../fonts/Boing/Boing-Regular.ttf") format("truetype");
    }

    @font-face {
        font-family: "Boing";
        font-weight: 500;
        src: local("Boing"), url("../fonts/Boing/Boing-Medium.ttf") format("truetype");
    }

    @font-face {
        font-family: "Boing";
        font-weight: 600;
        src: local("Boing"), url("../fonts/Boing/Boing-Semibold.ttf") format("truetype");
    }

    @font-face {
        font-family: "Boing";
        font-weight: 700;
        src: local("Boing"), url("../fonts/Boing/Boing-Bold.ttf") format("truetype");
    }

    body {
        color: #333;
        font-size: 18px;
        font-family: "Boing", sans-serif;
    }
}

@layer utilities {
    .font-tiny {
        font-size: 10px;
    }

    @media (min-width: 768px) {
        .text-md-lg {
            font-size: 1.0625rem;
            /* line-height: 1.625rem; */
        }
    }


    .header-links {
        @apply text-white mx-2 xl:mx-4 px-2 py-2 block uppercase hover:bg-black hover:bg-opacity-30;
        @apply border-b-2 border-transparent hover:border-white;
    }

    .header-links-mobile {
        @apply hover:text-tegdaffy-red;
    }

    .marketplace-gist-bg {
        height: 470px;
    }

    .bg-map {
        background-image: url("../images/bgs/map-bg.svg");
        background-size: cover;
        background-position: center;
    }

    .bg-map-red {
        background-image: url("../images/bgs/map-bg-red.svg");
        background-size: cover;
        background-position: center;
    }

    .bg-color-red {
        background-color: #e75a4e;
    }

    .form-input {
        @apply focus:ring-yellow-600;
    }

    .btn {
        @apply rounded-full hover:scale-110;
    }

    .btn-tegdaffy-red {
        @apply text-white bg-tegdaffy-red focus:ring-tegdaffy-red;
    }

    .btn-tegdaffy-blue {
        @apply text-white bg-tegdaffy-blue focus:ring-tegdaffy-blue;
    }

    .btn-transparent-black {
        @apply focus:ring-gray-400;
    }

    .btn-white {
        @apply bg-white text-black;
    }

    .our-services-list{
        @apply p-4 flex border-b border-gray-200 hover:bg-gray-200;
    }

    .our-services-list > div:nth-child(1){
        @apply font-bold mr-4 flex-none;
    }
    
    .our-services-list > div:nth-child(2){
        @apply flex-grow uppercase;
    }

    .our-services-list > div:nth-child(3){
        @apply ml-4 flex-none;
    }

    .img-bg3{
        background-image: url("../images/bgs/bg-3.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    .testimonies #carousel > figure > div {
        display: none;
    }
    .testimonies #carousel > figure.visible > div {
        display: flex;
        position: relative;
    }

    .sticky-top{
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 2
    }

}
