@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0px 1000px #fff inset;
        transition: background-color 5000s ease-in-out 0s;
        /* border: 1px solid #ffffff; */
        /* -webkit-text-fill-color: #ffffff; */
        @apply form-input;
    }
}

@layer utilities {
    .form-group {
        @apply mb-5 space-y-1;
    }

    .form-group label {
        @apply block;
    }

    .form-input-required {
        @apply ml-1 text-red-500;
    }

    .form-input {
        @apply w-full h-10 px-4 py-1 text-sm border border-gray-300 rounded focus:outline-none focus:ring-2 focus:border-transparent;
    }

    .form-input[disabled] {
        @apply bg-gray-200 opacity-100;
    }

    .form-input-sm {
        @apply form-input;
        @apply h-7 text-sm !important;
    }

    .btn {
        @apply w-max flex items-center justify-center space-x-2 rounded px-6 py-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-opacity-75;
    }

    .btn[disabled] {
        @apply cursor-default;
        opacity: 0.7;
        filter: opacity(70);
    }

    .btn>* {
        @apply my-auto;
    }

    .btn-sm {
        @apply h-7 px-3 py-1 text-sm;
    }

    .btn-md {
        @apply h-9 px-4 text-sm font-normal;
    }

    .btn-lg {
        @apply px-8 py-3;
    }

    .btn-block {
        @apply w-full;
    }

    .btn-transparent-black {
        @apply border border-gray-400;
    }

    .form-input-checkbox {
        /* @apply appearance-none text-ep-yellow bg-white border-2 border-gray-300 rounded cursor-pointer; */
        @apply appearance-none bg-white border-2 border-gray-300 rounded cursor-pointer;
    }

    .animate {
        @apply transform transition duration-500 ease-in-out;
    }

    .react-slideshow-container+ul.indicators .each-slideshow-indicator:before {
        background: #fff;
    }

    .sidebar-links-divider {
        @apply mt-6 mb-1 opacity-75 text-xs uppercase px-6 text-white;
    }

    .sidebar-links {
        @apply h-10 pr-3 flex text-white text-sm font-semibold items-center cursor-pointer;
    }

    .sidebar-links-highlight {
        @apply w-0.5 h-6 mr-6 bg-white invisible;
    }

    .sidebar-links.active .sidebar-links-highlight {
        @apply visible;
    }

    .sidebar-links-icon {
        @apply mr-5 object-contain;
    }

    .dropdown {
        @apply z-10 bg-white absolute rounded shadow-lg overflow-hidden;
        min-width: 100px;
        max-width: 200px;
        /* width: 150px; */
    }

    .dropdown-left {
        @apply left-0;
    }

    .dropdown-right {
        @apply right-0;
    }

    .dropdown-item {
        @apply px-4 py-1.5 text-sm block border-t border-gray-100 cursor-pointer hover:text-white hover:bg-blue-500;
    }

    .dropdown>.dropdown-item:nth-child(1) {
        @apply border-t-0;
    }

    .box {
        @apply p-4 xl:p-6 bg-white rounded-lg shadow overflow-hidden;
    }

    .box-no-padding {
        @apply p-0 xl:p-0;
    }

    .label {
        @apply px-2 py-1 text-xs rounded whitespace-nowrap inline font-bold;
    }

    .label-blue {
        @apply text-blue-600 bg-blue-100;
    }

    .label-green {
        @apply text-green-600 bg-green-100;
    }

    .label-red {
        @apply text-red-600 bg-red-100;
    }

    .label-yellow {
        @apply text-yellow-600 bg-yellow-100;
    }

    .table-container {
        @apply w-full overflow-x-auto overflow-y-hidden;
    }

    .table-container>.table th,
    .table-container>.table td {
        @apply w-min whitespace-nowrap;
    }

    .table {
        @apply w-full min-w-min;
    }

    .table.table-border th,
    .table.table-border td {
        @apply p-4 border-2 border-gray-100;
    }

    .table thead {
        background-color: #fafafb;
    }

    .table th {
        @apply text-xs text-left font-semibold uppercase;
        color: #8492a6;
    }

    .table td {
        @apply text-sm text-left;
        /* @apply text-sm text-left align-top; */
    }

    .table td .dropdown {
        @apply right-2 -ml-3 md:-ml-4;
    }

    .table-info-full-page {
        @apply box;
        @apply py-24 mt-6;
    }

    .table-info {
        @apply px-2 py-20 text-center text-gray-500;
    }

    .table-info>svg {
        @apply text-4xl mb-2;
    }

    .pagination {
        @apply flex rounded space-x-0.5 overflow-hidden;
    }

    .pagination-items {
        @apply w-8 h-8 flex bg-blue-100 cursor-pointer hover:bg-blue-500;
    }

    .pagination-items-icon {
        @apply w-4 h-4 m-auto text-blue-600 group-hover:text-white;
    }

    .pagination-items.disabled {
        @apply bg-gray-100 cursor-default hover:bg-gray-100;
    }

    .pagination-items-icon.disabled {
        @apply text-gray-300 group-hover:text-gray-300;
    }

    .tab-pill {
        @apply px-5 sm:px-10 py-1 m-auto text-sm rounded-lg hover:text-white hover:bg-blue-500;
    }

    .tab-pill-active {
        @apply bg-white shadow;
    }

    .ellipsis {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .ellipsis-2-lines,
    .ellipsis-two-lines,
    .ellipsis-line-2,
    .ellipsis-line-two,
    .ellipsis-lines-2,
    .ellipsis-lines-two {
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .tab {
        @apply py-3 mr-3 sm:mr-5 text-sm font-bold text-gray-500 hover:bg-blue-100;
    }

    .tab.active {
        @apply text-gray-600 border-b-2 border-blue-500;
    }

    .note-no-bg {
        @apply text-sm;
        /* padding: 5px 10px; */
        padding: 8px 15px;
        text-align: justify;
    }

    .note {
        @apply note-no-bg;
        background: #ffea77 !important;
    }

    .floated-content:after {
        display: block;
        content: "";
        clear: both;
    }

    .text-fade {
        @apply text-gray-400;
    }

    .container {
        @apply mx-auto px-4 xl:px-10;
    }

    #headlessui-menu-items-2 {
        outline: none !important;
    }

    .Toastify__toast-container {
        color: inherit;
        margin-top: 60px;
    }

    .Toastify__toast {
        min-height: 55px;
        font-size: 13px !important;
        padding: 10px 10px;
        background: #ffffff !important;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
        border-left: 10px solid transparent;
    }

    .Toastify__toast--default {
        background: transparent;
        border-color: #888;
    }

    .Toastify__toast--default .Toastify__progress-bar {
        /* height: 100%; */
        /* background: #0c0 !important; */
        opacity: 0.5 !important;
        filter: opacity(50%) !important;
    }

    .Toastify__toast--success {
        background: transparent;
        border-color: #0c0;
    }

    .Toastify__toast--success .Toastify__progress-bar {
        /* height: 100%; */
        background: #0c0 !important;
    }

    .Toastify__toast--error {
        background: transparent;
        /* border-color: #e74c3c; */
        border-color: #f00;
    }

    .Toastify__toast--error .Toastify__progress-bar {
        /* height: 100%; */
        background: #f00 !important;
    }

    .Toastify__toast-body {
        width: 100px;
        color: #000;
        font-weight: bold;
    }

    .Toastify__close-button {
        width: 30px !important;
        height: 30px !important;
        margin: auto;
        /* background: #000 !important; */
        border: 1px solid rgba(0, 0, 0, 1);
        border-radius: 5px;
    }

    .Toastify__close-button>svg {
        color: rgba(0, 0, 0, 1);
        margin: auto;
        /* display: none; */
    }

    .Toastify__close-button>svg::after {
        /* content: "OK" */
    }

    .Toastify__close-button--default {
        opacity: 1;
        filter: opacity(100%);
    }

    .Toastify__progress-bar {
        /* background: rgba(0, 0, 0, 0.1); */
        /* transform-origin: right; */
        opacity: 0.1;
        filter: opacity(10%);
        /* display: none; */
    }

    .object-cover {
        @apply w-full h-full;
    }
}